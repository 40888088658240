import axios from 'axios';
import React, { useState } from 'react'


export default function Forget_Password() {

    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const forget_password_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_MEETING_FORGET_PASSWORD;


    const HandleLoginSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        axios.get(forget_password_url, {
            params: {
                email: email
            }
        }).then(response => {
            if (response.status == 200) {
                setSuccessMessage("A password reset link has been sent to your email");
            }

        }).catch(error => {
            setErrorMessage(error.response.data.message);
        });

    };

    return (
        <div>
            <div>
                <div className='container-fluid user-container border-top'>
                    <main id="main">

                        <section id="breadcrumbs" className="breadcrumbs">
                            <div className="container">
                                <ol>
                                    <li><a href="/app">Home</a></li>
                                    <li>Forgot Password</li>
                                </ol>
                            </div>
                        </section>
                        <section className="tg-may-account-wrapp tg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 detail">
                                        <div className="inner">
                                            <h2>Forgot your password?</h2>
                                            <p className="description">
                                                Please provide your email address that you used when you signed up for the account.
                                                <br />
                                                We will send you an email that will allow you to reset password.
                                            </p>
                                            <form className="tg-form" onSubmit={HandleLoginSubmit}>
                                                {errorMessage &&
                                                    <div class="alert alert-danger alert-dismissible fade show">{errorMessage}
                                                        {/* <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span></button> */}
                                                    </div>
                                                }
                                                {successMessage &&
                                                    <div class="alert alert-success alert-dismissible fade show">{successMessage}
                                                        {/* <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span></button> */}
                                                    </div>
                                                }
                                                <div class="form-group row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">Email</label>
                                                    <div class="col-sm-10">
                                                        <input type="email" className="form-control shadow-none" value={email} autoFocus
                                                            onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
                                                    </div>
                                                </div>
                                                {/* <p class="description">
                                                    We will send you an email that will allow you to reset password.
                                                </p>    */}
                                                <div class="form-group row">
                                                    <label for="button" class="col-sm-2 col-form-label"></label>
                                                    <div class="col-sm-10">
                                                        <button type="submit" className="btn btn-success">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </main>
                </div>
            </div>
        </div>
    )
}
