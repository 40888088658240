function EmailVerification() {
  const center = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%"
  }
  return (
    <div className='container-fluid user-container border-top'>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol> 
              <li><a href="/">Home</a></li>
              <li>Registration Success</li>
            </ol>
          </div>
        </section>
        <section className="tg-may-account-wrapp tg">
          <div className="container">
            <div className="row">
              <div className="col-md-12 detail text-center" >
                <img  src="assets/img/ok-icon.png" height="60" width="60" /> <br /> <br />
                <h2>Thank you for your registration!</h2>
                <p className="description" style={{color:"red"}}>Your account will be activated once the admin approves the request.</p>
                <p>You will get an account activation confirmation email after approval.</p>
                <a href="/" className="btn btn-success">Browse Boithok</a>
              </div>              
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default EmailVerification;