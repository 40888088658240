import React from 'react'
import { Link } from 'react-router-dom';

export default function SubscriptionRequest() {

  return (
    <div>
      <div className="container">
        <h4 class="pb-4">Buy subscription</h4>
        <div class="row col-md-12">
          <p>
            <span style={{color:"red"}}>You have no active subscription in Boithok!</span> A subscription is required to host meetings in Boithok.
          </p>
          <p><Link to="/buy_subscription" className="btn btn-success">Subscribe here</Link></p>
        </div>
      </div>
    </div>)
}
