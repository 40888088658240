import { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom'

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState();
  const [password, setPassword] = useState();
  const [rePassword, setRePassword] = useState();
  const [flag, setFlag] = useState(true);

  const [error, setError] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [matchError, setMatchError] = useState("");

  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;


  const jwt = localStorage.getItem('token');

  const passwordChangeUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_PASSWORD;

  const navigate = useNavigate();

  useEffect(() => {
    if (!jwt) {
      navigate("/login");
    }
  }, [jwt, navigate]);

  const header = {
    headers: {
      'Authorization': 'Bearer ' + jwt
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    // const validationError = formValidation();

    // if (Object.keys(validationError).length === 0) {
    //   console.log("Change password form has no error");
    // }
    // else {
    //   setError(validationError);
    //   return;
    // }

    const response = await axios.put(passwordChangeUrl,
      {
        currentPassword,
        password,
        rePassword
      }, header).then(response => {
        if (response.status === 200) {
          console.log("Password changed successfully");
        }
        setFlag(false);
      }).catch(error => {
        let serverError = {};
        if (error.response.status == "400") {
          serverError.currentPassword = "Current password doesn't match";
          setError(serverError);
        }
        if (error.response.status == "401") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      })
  }

  const validatePasswords = (password, rePassword) => {   
     
    if (rePassword && rePassword !== password) {
      setMatchError("Passwords do not match.");
    } else {
      setMatchError("");
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (!passwordRegex.test(value)) {
      setPasswordError(
        "Password must be at least 8 characters long, include one uppercase letter, and one special character."
      );
    } else {
      setPasswordError("");
    }

    validatePasswords(value, rePassword);
  };

  const handleRePasswordChange = (e) => {
    const value = e.target.value;
    setRePassword(value);
    
    validatePasswords(password, value);
  };

  return (
    <div className='container-fluid user-container border-top'>
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">
          <ol>
            <li><a href="/">Home</a></li>
            <li>Change Password</li>
          </ol>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row  col-md-8">
            <h4 className='my-4'>Change Password</h4>
            {flag &&
              <form onSubmit={handleSubmit}>

                <div className="form-group row">
                  <label for="password" class="col-sm-4 col-form-label">Current Password*</label>
                  <div class="col-sm-8">
                    <input type="password" name="currentPassword" onChange={(e) => setCurrentPassword(e.target.value)}
                      className="form-control shadow-none" placeholder="Enter current password" required />
                    {error.currentPassword && <span className="text-danger">{error.currentPassword}</span>}
                  </div>
                </div>

                <div className="form-group row">
                  <label class="col-sm-4 col-form-label">New Password*</label>
                  <div class="col-sm-8">
                    <input type="password" name="password" className="form-control shadow-none"
                      // onChange={(e) => setPassword(e.target.value)} minLength={8}
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder="Enter new password" required />
                    {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
                    {/* {error.password && <span className="text-danger">{error.password}</span>} */}
                  </div>
                </div>

                <div className="form-group row">
                  <label class="col-sm-4 col-form-label">Confirm New Password*</label>
                  <div class="col-sm-8">
                    <input type="password" name="rePassword" className="form-control shadow-none"
                      // onChange={(e) => setRePassword(e.target.value)} minLength={8}
                      value={rePassword}
                      onChange={handleRePasswordChange}
                      placeholder="Re-enter new password" required />
                    {/* {error.rePassword && <span className="text-danger">{error.rePassword}</span>} */}
                    {matchError && <p style={{ color: "red" }}>{matchError}</p>}
                  </div>
                </div>

                <div className="form-group row">
                  <label class="col-sm-4 col-form-label"></label>
                  <div class="col-sm-8">
                    <button type="submit" className="btn btn-primary" >Change Password</button>
                  </div>
                </div>
              </form>
            }
            {
              !flag && <div> <span className='pt-2 text-success h4'>Password changed successfully</span></div>
            }
          </div>
        </div>
      </section>
    </div>
  )
}
