
function NotFound() {
  return (
    <div className='container-fluid user-container border-top'>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><a href={`${process.env.PUBLIC_URL}`}>Home</a></li>
              <li>Page Not Found</li>
            </ol>
          </div>
        </section>
        <section className="tg-may-account-wrapp tg">
          <div className="container">
            <div className="row">
              <div className="not-found-container">
                <div className="not-found-content">
                  <h1>404</h1>
                  <h2>Oops! Page Not Found</h2>
                  <p>The page you're looking for doesn't seem to exist.</p>
                  {/* You can add additional elements, like a button to navigate back */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default NotFound;