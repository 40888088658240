import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom';

export default function Reset_Password() {

    const location = useLocation();

    const [token, setToken] = useState();

    const [newPassword, setNewPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        const searchParam = new URLSearchParams(location.search);
        setToken(searchParam.get("token"));
        console.log("Token : " + token);

    }, [location.search])



    const reset_password_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_MEETING_RESET_PASSWORD;

    const HandleLoginSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (newPassword != rePassword) {
            setErrorMessage("Password didn't match");
            return;
        }

        console.log("forget_password_url : " + reset_password_url);

        axios.post(reset_password_url, {
            token,
            newPassword,
            rePassword
        }).then(response => {
            if (response.status == 200) {
                setSuccessMessage("A password reset has been done successfully");
            }
            console.log("Response " + JSON.stringify(response, null, 2));
        }).catch(error => {
            if (error.response.status == 400 || error.response.status == 500) {
                console.log("Time has expired, please try again");
                setErrorMessage("Time has expired, please ");
            }
            console.log("Error in request " + JSON.stringify(error, null, 2));
            //   setMessage('Login Failed. Please check your credentials');
        });

    };

    return (

        <div className='container-fluid user-container border-top'>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Reset Password</li>
                    </ol>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row col-md-8">
                        <h4 className='my-4'>Please enter new password</h4>
                        <form className="tg-form" onSubmit={HandleLoginSubmit}>
                            <div className="form-group row">
                                <label for="password" class="col-sm-3 col-form-label">Password</label>
                                <div class="col-sm-9">
                                    <input type="password" className="form-control shadow-none" value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)} placeholder="Password" required />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="confirmpassword" class="col-sm-3 col-form-label">Confirm Password</label>
                                <div class="col-sm-9">
                                    <input type="password" className="form-control shadow-none" value={rePassword}
                                        onChange={(e) => setRePassword(e.target.value)} placeholder="Confirm password" required />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="confirmpassword" class="col-sm-3 col-form-label"></label>
                                <div class="col-sm-9">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </div>

                            <div className='pt-3'>
                                {errorMessage && <span className="text-danger">{errorMessage}<Link to="/forget_password"><u>try again</u></Link></span>}
                                {successMessage && <span className="text-success">{successMessage}<Link to="/login"> Login</Link></span>}
                            </div>
                        </form>

                    </div>
                </div>
            </section>
        </div>

    )
}
