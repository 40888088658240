import { useState, useEffect } from 'react'
import axios from "axios";

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState();
  const [password, setPassword] = useState();
  const [rePassword, setRePassword] = useState();
  const [flag, setFlag] = useState(true);

  const [error, setError] = useState({});
  const jwt = localStorage.getItem('token');

  const passwordChangeUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_PASSWORD;


  const header = {
    headers: {
      'Authorization': 'Bearer ' + jwt
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    const validationError = formValidation();

    if (Object.keys(validationError).length === 0) {
      console.log("Change password form has no error");
    }
    else {
      setError(validationError);
      return;
    }

    const response = await axios.put(passwordChangeUrl,
      {
        currentPassword,
        password,
        rePassword
      }, header).then(response => {
        console.log("Response data : " + JSON.stringify(response, null, 2));
        if (response.status === 200) {
          console.log("Password change successfully");
        }
        setFlag(false);
      }).catch(error => {
        console.error("Error in registration + " + JSON.stringify(error, null, 2));
        let serverError = {};
        if (error.response.status == "400") {
          serverError.currentPassword = "Current password doesn't match";
          setError(serverError);
        }
      })
  }

  const formValidation = () => {

    const validationError = {};

    if (currentPassword === null || currentPassword === '' || currentPassword === undefined) {
      validationError.currentPassword = "Current Password can't be empty";
    }

    if (password === null || password === '' || password === undefined) {
      validationError.password = "Password can't be empty";
    }

    if (rePassword === null || rePassword === '' || rePassword === undefined) {
      validationError.rePassword = "Confirm Password can't be empty";
    }

    if (rePassword != password) {
      validationError.password = "New password doesn't match with Confirm password";
    }
    return validationError;
  }



  return (
    <div className='container-fluid user-container border-top'>
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">
          <ol>
            <li><a href="/">Home</a></li>
            <li>Change Password</li>
          </ol>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row  col-md-8">
            <h4 className='my-4'>Change Password</h4>
            {flag &&
              <form onSubmit={handleSubmit}>

                <div className="form-group row">
                  <label for="password" class="col-sm-3 col-form-label">Current Password*</label>
                  <div class="col-sm-9">
                    <input type="password" name="currentPassword" onChange={(e) => setCurrentPassword(e.target.value)}
                      className="form-control shadow-none" placeholder="Enter Current Password" required />
                    {error.currentPassword && <span className="text-danger">{error.currentPassword}</span>}
                  </div>
                </div>

                <div className="form-group row">
                  <label class="col-sm-3 col-form-label">New Password*</label>
                  <div class="col-sm-9">
                    <input type="password" name="password" className="form-control shadow-none" onChange={(e) => setPassword(e.target.value)} minLength={8}
                      placeholder="Password length must be at least 8 characters" required />
                    {error.password && <span className="text-danger">{error.password}</span>}
                  </div>
                </div>

                <div className="form-group row">
                  <label class="col-sm-3 col-form-label">Confirm Password*</label>
                  <div class="col-sm-9">
                    <input type="password" name="rePassword" onChange={(e) => setRePassword(e.target.value)} className="form-control shadow-none" minLength={8}
                      placeholder="Password length must be at least 8 characters" required />
                    {error.rePassword && <span className="text-danger">{error.rePassword}</span>}
                  </div>
                </div>

                <div className="form-group row">
                  <label class="col-sm-3 col-form-label"></label>
                  <div class="col-sm-9">
                    <button type="submit" className="btn btn-primary" >Change Password</button>
                  </div>
                </div>
              </form>
            }
            {
              !flag && <div> <span className='pt-2 text-success h4'>Password changed successfully</span></div>
            }
          </div>
        </div>
      </section>
    </div>
  )
}
