import Success_Icon from "../resources/payment-success_icon.png";
import Failure_Icon from "../resources/payment-fail-icon.png";
import { Link, useLocation } from 'react-router-dom';

function PaymentStatus() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    return (

        <div className='container-fluid user-container border-top'>
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li>Payment Status</li>
                        </ol>
                    </div>
                </section>
                <section className="tg-may-account-wrapp tg">
                    <div className="container">
                        <h4></h4>
                        <div className="row">
                            <div className="col-md-12 detail text-center" >
                                {type === 'failure' ? (
                                    <p><img src={Failure_Icon} width="100" height="100" />
                                        <h2>Payment Failed!</h2>
                                        <p className="description">Your payment has failed. Please try again.</p></p>
                                ) : (
                                    <>
                                        <img src={Success_Icon} width="100" height="100" />
                                        <h2>Payment Successful!</h2>
                                        <p className="description">Thanks for choosing Boithok!</p>
                                    </>
                                )}

                                <Link to="/meeting_dashboard" className="btn btn-success">Back To Dashboard</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div >
    )
}

export default PaymentStatus;