import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Join_Meeting_Icon from "../resources/join_meeting_icon.png";
import axios from 'axios';
import { useRef } from 'react';
// import 'e:/Boithok/Code/boithok-um-ui/node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

export default function Home() {

  const modalRef = useRef(null);
  const [meetingId, setMeetingId] = useState("");
  const [passcode, setPasscode] = useState("");
  const [validationMsg, setValidationMsg] = useState("");

  const navigate = useNavigate();

  let jwt = localStorage.getItem('token');
  let activeSubscription = localStorage.getItem('activeSubscription');

  const header = {
    headers: {
      'Authorization': 'Bearer ' + jwt
    }
  }
  console.log("Authorization Meeting History" + header);
  const purchase_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PAYMENT_API;
  const contact_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CONTACT_API;
  console.log("Purchase url : " + purchase_url);

  const HandleGuestJoinMeetingSubmit = async (event) => {
    event.preventDefault();
    let mId = meetingId.replace(/ /g, '');

    const guestMeetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GUEST_JOIN_MEETING_URL + '?' + 'meetingID=' + mId + '&passcode=' + passcode;
    axios.post(guestMeetingUrl).then(response => {
      console.log(" Join meeting response: " + JSON.stringify(response, null, 2));
      window.open(response.data.url, "_blank");
      navigate("/join_meeting_success");
    }).catch(error => {
      setValidationMsg("No meeting found with the specified ID!");
      setTimeout(() => {
        setValidationMsg(''); 
      }, 3000);
    });
  }

  const listyle = {
    color: "#3F8755"
  }
  const meetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_START_MEETING;
  const startMeeting = (e) => {
    if (activeSubscription == "false" && jwt) {
      navigate("/meeting_dashboard");
      return;
    }
    axios.get(meetingUrl, header)
      .then(response => {
        window.open(response.data.url, "_blank");
      }).catch(error => {
        if (error.response.status == "401") {
          localStorage.removeItem("token");
          navigate("/login");
        }

      })
  }

  return (
    <div>
      {/* <div class="hero-header overflow-hidden px-5"> */}
      <div class="container hero-header overflow-hidden">
        <div class="rotate-img">
          <img src={`${process.env.PUBLIC_URL}/assets/img/sty-1.png`} class="img-fluid w-100" alt=""></img>
          <div class="rotate-sty-2"></div>
        </div>
        <div class="row gy-5 align-items-center">          
            <div class="col-lg-6">
            <h1 class="display-5 text-dark mb-4">Join or Start Meeting Securely & Instantly </h1>
            {/* <p class="fs-4 mb-4">Streamline communication, Increase employee engagement & Improve productivity with Boithok</p> */}
            <p class="fs-4 mb-4">Enhance communication, increase engagement, and improve productivity with Boithok</p>
            <div class="row gy-2 justify-content-start">
              <div class="col-12 col-md-4">
                <button className="btn btn-primary d-block rounded-pill py-3 px-4 woww fadeInUp" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Join Meeting
                </button>
              </div>
              <div class="col-12 col-md-4">
                <button className="btn btn-secondary d-block rounded-pill py-3 px-4 woww fadeInUp" onClick={startMeeting}>
                  Start Meeting
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <img src={`${process.env.PUBLIC_URL}/assets/img/conference.png`} class="img-fluid w-100 h-100" alt=""></img>
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Join in a meeting</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={HandleGuestJoinMeetingSubmit}>
              <div className="modal-body invitaion-info text-center">
                <img src={Join_Meeting_Icon} height="120" width="120" className="p-3 " />
                <div className="row px-4">
                  <input
                    type="text"
                    className="form-control shadow-none"
                    value={meetingId}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,12}$/.test(value)) {
                        setMeetingId(value);
                      }
                    }}
                    placeholder="Enter meeting ID (12 digits)"
                    required
                  />
                </div>
                <div className="row px-4">
                  <input
                    type="text"
                    className="form-control shadow-none"
                    value={passcode}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,4}$/.test(value)) {
                        setPasscode(value);
                      }
                    }}
                    placeholder="Enter passcode (4 digits)"
                    required
                  />
                  {/* <input type="text" className="form-control shadow-none" value={passcode} onChange={(e) => setPasscode(e.target.value)} placeholder="Enter passcode (4 digits)" required pattern="\d{4}" /> */}
                </div>
                <div className="row px-4">
                  <span style={{ color: "red" }}>{validationMsg}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Join Meeting</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
