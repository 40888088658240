import React from 'react'
import { Link } from 'react-router-dom';

export default function SubscriptionRequest() {

  return (
    <div>
      <div className="container">
        <h4 class="pb-4">Buy subscription</h4>
        <div class="row col-md-12">
          <p style={{ color: "red", fontSize: "24px" }}>
            You have no active subscription now!
          </p>
          <p>A subscription is required to host meetings in Boithok.</p>
          <p style={{ paddingTop: "20px" }}><Link to="/buy_subscription" className="btn btn-success">Subscribe here</Link></p>
        </div>
      </div>
    </div>)
}
