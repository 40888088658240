import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
// import not_started_icon from "../resources/not-started.png";
import not_started_icon from "../resources/waiting.gif";


function CheckMeetingStatus() {
  const location = useLocation();
  const [invitLink, setInvitLink] = useState();

  useEffect(() => {
    const searchParam = new URLSearchParams(location.search);
    setInvitLink(searchParam.get("invitLink"));

  }, [location.search])

  const tryAgain = () => {
    window.open(invitLink, "_self");
  }

  return (
    <div className='container-fluid user-container border-top'>
      <main id="main">
        <section id="breadcrumbs" className="breadcrumbs">
          <div className="container">
            <ol>
              <li><a href="/app">Home</a></li>
              <li>Meeting Status</li>
            </ol>
          </div>
        </section>
        <section style={{padding: "50px 0px"}}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 detail text-center" >
                {/* <img src={not_started_icon} height="50" width="50" /> <br /> <br /> */}
                <img src={not_started_icon}  /> <br /> <br />
                <h2>The host has not started meeting, try again!</h2>
                {/* <p className="description"></p>                 */}
                <button className='btn btn-primary btn-success' onClick={() => tryAgain()}>Try Again</button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
};
export default CheckMeetingStatus;