import React from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { spaceAfter4digit } from './date_time';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css'; // Import react-toastify CSS

export default function StartMeeting() {
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/login");
        }
    }, [])


    let meetingId = localStorage.getItem('meetingId');
    let jwt = localStorage.getItem('token');
    let name = localStorage.getItem('name');
    let passcode = localStorage.getItem('passcode');
    let invitationLink = localStorage.getItem('invitationLink');

    const [copyInvitLink, setcopyInvitLink] = useState('');
    const [meetingTitle, setMeetingTitle] = useState(`${name}'s personal meeting`);
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        const textToCopy = document.getElementById("invitationLink").innerText;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const copyInvitationLink = () => {
        let copyInvitL = "Meeting Title : " + meetingTitle +
            "\n\n Meeting Id : " + meetingId +
            "\n\n Passcode : " + passcode +
            "\n\n Platform : Boithok" +
            "\n\n Invitation Link : " + invitationLink;
        setcopyInvitLink(copyInvitL);
        navigator.clipboard.writeText(copyInvitL);
        console.log(copyInvitL);
        toast.success('Invitation link copied',
            {
                autoClose: 2000,
                closeOnClick: true,
                closeButton: true
            });
    }

    const meetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_START_MEETING;

    const header = {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    }
    // console.log("Authorization " + header.Authorization);

    const startMeeting = (e) => {
        console.log(jwt);
        console.log("Meeting url " + meetingUrl);
        console.log("header " + JSON.stringify(header));

        axios.get(meetingUrl+"?title="+meetingTitle, header)
            .then(response => {
                console.log(" Response : " + JSON.stringify(response));
                console.log(" Response : " + response.data.url);
                window.open(response.data.url, "_blank");
            }).catch(error => {
                console.log(" Error Here : " + JSON.stringify(error, null, 2));
                console.log(error.response.status);
                if (error.response.status == "401") {
                    localStorage.removeItem("token");
                    navigate("/login");
                }

            })
    }

    return (
        <div className="container">

            <div className="row">
                <div className="col-md-12">
                    <h4 className="pb-4">Start a meeting instantly</h4>
                    <div className="row">
                        <div className="col-12 d-flex py-2 flex-wrap">
                            <div className="col-12 col-md-3">
                                <div className="form-label"><strong>Meeting Title</strong></div>
                            </div>
                            <div className="col-12 col-md-6">
                                {/* <div>{meetingTitle}</div> */}
                                <input type="text" className="form-control shadow-none"
                                    value={meetingTitle}
                                    onChange={(e) => setMeetingTitle(e.target.value)}
                                    placeholder="Enter Meeting Title" />
                            </div>
                        </div>

                        <div className="col-12 d-flex py-2 flex-wrap">
                            <div className="col-12 col-md-3">
                                <div className="form-label"><strong>Personal Meeting ID</strong></div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div>{meetingId}</div>
                            </div>
                        </div>

                        <div className="col-12 d-flex py-2 flex-wrap">
                            <div className="col-12 col-md-3">
                                <div className="form-label"><strong>Passcode</strong></div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div>{passcode}</div>
                            </div>
                        </div>

                        <div className="col-12 d-flex py-2 flex-wrap">
                            <div className="col-12 col-md-3">
                                <div className="form-label"><strong>Invitation Link</strong></div>
                            </div>
                            <div className="col-12 col-md-9">
                                <div>
                                    <span id="invitationLink">{invitationLink}</span>
                                    <span onClick={handleCopy} style={{ cursor: 'pointer', marginLeft: '20px' }}>
                                        {copied ? 'Copied!' : <i className="fa fa-copy" title="Copy link"></i>}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 d-flex py-2 flex-wrap">
                            <div className="col-12 col-md-3"></div>
                            <div className="col-12 col-md-9">
                                <button className="btn btn-success" onClick={startMeeting}>
                                    Start Meeting
                                </button>
                                &nbsp;&nbsp;
                                <button className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Copy Invitation
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <ToastContainer /> */}
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Copy Invitation Information</h5>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body invitaion-info">
                                <p>{name} is inviting you to join in meeting</p>
                                <p>Meeting Title : {meetingTitle}</p>
                                <p>Meeting ID : {meetingId}</p>
                                <p>Passcode : {passcode}</p>
                                <p>Platform : Boithok</p>
                                <p>Invitation Link: {invitationLink}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={copyInvitationLink}>Copy Invitation Info</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>)
}
