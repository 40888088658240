import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Join_Meeting_Icon from "../resources/join_meeting_icon.png";
import axios from 'axios';
import { useRef } from 'react';
// import 'e:/Boithok/Code/boithok-um-ui/node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

export default function Home() {

  const modalRef = useRef(null);
  const [meetingId, setMeetingId] = useState("");
  const [passcode, setPasscode] = useState("");

  const [mobileNo, setMobileNo] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [showContactForm, setShowContactForm] = useState(true);
  const [contactMassage, setContactMessage] = useState("");

  const navigate = useNavigate();

  let jwt = localStorage.getItem('token');
  let activeSubscription = localStorage.getItem('activeSubscription');

  const header = {
    headers: {
      'Authorization': 'Bearer ' + jwt
    }
  }
  console.log("Authorization Meeting History" + header);
  const purchase_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PAYMENT_API;
  const contact_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CONTACT_API;
  console.log("Purchase url : " + purchase_url);

  const HandleGuestJoinMeetingSubmit = async (event) => {
    event.preventDefault();
    let mId = meetingId.replace(/ /g, '');

    const guestMeetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GUEST_JOIN_MEETING_URL + '?' + 'meetingID=' + mId + '&passcode=' + passcode;
    axios.post(guestMeetingUrl).then(response => {
      console.log(" Join meeting response: " + JSON.stringify(response, null, 2));

      //   // Close the modal programmatically before opening the new tab
      //  const modalElement = document.getElementById('exampleModal');
      //   const modalInstance = new bootstrap.Modal(modalElement);
      //   modalInstance.hide();  // Close the modal

      window.open(response.data.url, "_blank");
      navigate("/join_meeting_success");
    }).catch(error => {
      console.log("Error in request " + JSON.stringify(error, null, 2));
    });
  }

  const HandleContactFormSubmit = async (event) => {
    event.preventDefault();
    let data = new FormData();
    data.append('mobile', mobileNo);
    data.append('name', name);
    data.append('email', email);
    data.append('message', message);
    data.append('subject', subject);
    console.log("contact url: " + contact_url);
    axios.post(contact_url, data)
      .then(response => {
        console.log("Response data : " + JSON.stringify(response, null, 2));
        if (response.status == 200) {
          setContactMessage("Your message has beed sent successfully");
          setShowContactForm(false);
        }
      }).catch(error => {
        setContactMessage("There is a error in server");
        setShowContactForm(false);
      })
  }

  const listyle = {
    color: "#3F8755"
  }
  const meetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_START_MEETING;
  const startMeeting = (e) => {
    if (activeSubscription == "false" && jwt) {
      navigate("/meeting_dashboard");
      return;
    }
    axios.get(meetingUrl, header)
      .then(response => {
        window.open(response.data.url, "_blank");
      }).catch(error => {
        if (error.response.status == "401") {
          localStorage.removeItem("token");
          navigate("/login");
        }

      })
  }

  return (
    <div>
      {/* <div class="hero-header overflow-hidden px-5"> */}
      <div class="container hero-header overflow-hidden">
        <div class="rotate-img">
          <img src={`${process.env.PUBLIC_URL}/assets/img/sty-1.png`} class="img-fluid w-100" alt=""></img>
          <div class="rotate-sty-2"></div>
        </div>
        <div class="row gy-5 align-items-center">
          <div class="col-lg-6 woww fadeInLeft" data-woww-delay="0.1s">
            <h1 class="display-5 text-dark mb-4 woww fadeInUp" data-woww-delay="0.3s">Join or Start Meeting Securely & Instantly </h1>
            <p class="fs-4 mb-4 woww fadeInUp" data-woww-delay="0.5s">Streamline communication, Increase employee engagement & Improve productivity with Boithok</p>

            <div class="row gy-2 justify-content-start">
              <div class="col-12 col-md-4">
                {/* <a href="" class="btn btn-primary d-block rounded-pill py-3 woww fadeInUp" data-bs-toggle="modal" data-bs-target="#exampleModal">Join Meeting</a> */}
                <button className="btn btn-primary d-block rounded-pill py-3 px-4 woww fadeInUp" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Join Meeting
                </button>
              </div>
              <div class="col-12 col-md-4">
                {/* <a href="" class="btn btn-secondary d-block rounded-pill py-3 woww fadeInUp" onClick={startMeeting}>Start Meeting</a> */}
                <button className="btn btn-secondary d-block rounded-pill py-3 px-4 woww fadeInUp" onClick={startMeeting}>
                  Start Meeting
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-6 woww fadeInRight" data-woww-delay="0.2s">
            <img src={`${process.env.PUBLIC_URL}/assets/img/conference.png`} class="img-fluid w-100 h-100" alt=""></img>
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Join in a meeting</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={HandleGuestJoinMeetingSubmit}>
              <div className="modal-body invitaion-info text-center">
                <img src={Join_Meeting_Icon} height="120" width="120" className="p-3 " />
                <div className="row px-4">
                  <input type="text" className="form-control shadow-none" value={meetingId} onChange={(e) => setMeetingId(e.target.value)} placeholder="Enter meeting ID (12 digits)" required pattern="\d{12}" />
                </div>
                <div className="row px-4">
                  <input type="text" className="form-control shadow-none" value={passcode} onChange={(e) => setPasscode(e.target.value)} placeholder="Enter passcode (4 digits)" required pattern="\d{4}" />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Join Meeting</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
