import './App.css';
import {
  HashRouter,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Navbar from './components/navbar';
import Home from './components/home';
import Footer from './components/footer';
import About from './components/about';
import Features from './components/features';
import NotFound from './components/not_found';
import MeetingDashboard from './components/meeting_dashboard';
import Login from './components/login';
import Registration from './components/registration';
import Forget_Password from './components/forget_password';
import Reset_Password from './components/reset_password';
import ChangePassword from './components/change_password';
import RegistrationSuccess from './components/registration_success';
import CheckMeetingStatus from './components/check_meeting_status';
import JoinMeetingSuccess from './components/join_meeting_success';
import Profile from './components/profile';
import FAQ from './components/faq';
import Subscription from './components/subscription';
import PaymentStatus from './components/payment_status';
import Terms from './components/terms';
import PrivacyPolicy from './components/privacy_policy';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const baseUrl = process.env.PUBLIC_URL;
  return (
    <BrowserRouter basename={baseUrl}>
      <Navbar />
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Features />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/meeting_dashboard" element={<MeetingDashboard />} />
        <Route path="/forget_password" element={<Forget_Password />} />
        <Route path="/reset_password" element={<Reset_Password />} />
        <Route path="/registration_success" element={<RegistrationSuccess />} />
        <Route path="/check_meeting_status" element={<CheckMeetingStatus />} />
        <Route path="/join_meeting_success" element={<JoinMeetingSuccess />} />
        <Route path="/change_password" element={<ChangePassword />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/buy_subscription" element={<Subscription />} />
        <Route path="/payment_status" element={<PaymentStatus />} />
        <Route path="/terms_and_conditions" element={<Terms />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path='*' element={<NotFound />} />

      </Routes>
      <Footer />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
