import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import video_download_Icon from "../resources/video_download_Icon.png";
import attendence_icon from "../resources/attendence_icon.png";
import chat_history_icon from "../resources/chat_history_icon.png";

export default function MeetingHistory() {
    const [meetingData, setMeetingData] = useState([]);

    const [start, setStart] = useState();
    const [end, setEnd] = useState();

    const navigate = useNavigate();
    const meetingHistoryUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_MEETING_HISTORY_URL;
    let jwt = localStorage.getItem('token');

    const header = {
        headers : {
            'Authorization' : 'Bearer '+ jwt
        }
    }    
    console.log("Authorization Meeting History" + header);

    useEffect(() => {        
        axios.get(meetingHistoryUrl, header)
        .then(response =>{
            console.log("Success");
            console.log( " Response  : "+ JSON.stringify(response , null, 2));
            setMeetingData(response.data);

        })
        .catch(error =>{
            console.log("Request is not get any response");
            console.log( "***************** Error Here : "+ JSON.stringify(error , null, 2));  
            if(error.response.status == "401")  {
                localStorage.removeItem("token");
                navigate("/login");
            }
        })

    }, [])

    const getMeetingHistoryListByDate = (event) =>{
        event.preventDefault();  
        let url = meetingHistoryUrl + "/range?start=" + start + "&end=" + end;
        console.log("url " + url);
        axios.get(
            url, header
        ).then(response =>{
            console.log("Success ====>  " + JSON.stringify(response , null, 2));            
            setMeetingData(response.data);
        })
        .catch(error =>{
            console.log("Request is not get any response");
            console.log( "***************** Error Here : "+ JSON.stringify(error , null, 2));  
            if(error.response.hhhh == "401")  {
                localStorage.removeItem("token");
                navigate("/login");
            }
        })

    }

    const renderVideoRecordingAnchor = (link) =>{
        if(link.trim() === "" ){
            // return <span className="p-2"> <img src={video_download_Icon} height="30" width="30" className="blur-icon" title="No Video Recording Found"/></span>;
        }
        else{
            return <a href={link} className="p-2" title="Video Recording Download"> <img src={video_download_Icon} height="30" width="30"/> </a>;
        }

    }

    const renderChatHistoryAnchor = (link) =>{
        if(link.trim() === "" ){
            // return <span className="p-2"><img src={chat_history_icon} height="23" width="23" className="blur-icon" title="No Chat History Found"/></span>;
                   
        }
        else{
            return <a href={link} className="p-2" title="Chat History Download"> <img src={chat_history_icon} height="23" width="23"/> </a>;
        }

    }

    const renderAttendanceDownloadAnchor = (link) =>{
        if(link.trim() === "" ){
            // return <span className="p-2"><img src={attendence_icon} height="30" width="30" className="blur-icon" title="No Attendance Found"/></span>;
        }
        else
        {
            return <a href={link} className="p-2" title="Attendence Download"> <img src={attendence_icon} height="30" width="30"/> </a>;
        }

    }

  return (
    <div className="container mt-4">
    <form onSubmit={getMeetingHistoryListByDate}>
    <div className="row">
        <div className="col-12 col-md-6">
            <h4 className="p-2">Meeting History</h4>
        </div>
        <div className="col-12 col-md-6">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
                <div className="p-2 inlineDisply ">
                    <label className="me-2">Start</label>
                    <input
                        type="date"
                        name="start"
                        onChange={(e) => setStart(e.target.value)}
                        required
                        className="form-control"
                    />
                </div>
                <div className="p-2 inlineDisply">
                    <label className="me-2">End</label>
                    <input
                        type="date"
                        name="end"
                        onChange={(e) => setEnd(e.target.value)}
                        required
                        className="form-control"
                    />
                </div>
                <div className="p-2  text-center">
                    <label className="me-2"></label>
                    <input type="submit" value="Search" className="btn btn-primary form-control" />
                </div>
            </div>
        </div>
    </div>

    </form>

    <table className="table table-bordered custom-table">
    <thead>
        <tr>
            <th>SL</th>
            <th>Meeting ID</th>
            <th>VC_UUID</th>
            <th>Title</th>
            <th>Start Time </th>
            <th>End Time</th>
            <th>Downloads</th>                  
        </tr>
    </thead>
    <tbody>

        {meetingData.map((item, index) => 
        <tr>
            <td>{index+1}</td>
            <td>{item.meetingID}</td>
            <td>{item.vcUUID}</td>
            <td>{item.title}</td>
            <td>{new Date(item.startTime).toLocaleString()}</td>
            <td>{new Date(item.endTime).toLocaleString()}</td>
            <td>       

                {renderAttendanceDownloadAnchor(item.attendanceLink)}                                  
                {/* <a href={item.attendanceLink} className="p-2" title="Attendence Download">
                    <img src={attendence_icon} height="30" width="30"/>
                </a> */}
                {renderVideoRecordingAnchor(item.recordingLink)}   
                {/* <a href={item.recordingLink} className="p-2" >
                    <img src={video_download_Icon} height="30" width="30" title="Video"/>
                </a>  */}

                {renderChatHistoryAnchor(item.chatLink)}
                {/* <a href={item.chatLink} className="p-2" title="Chat History Download">
                    <img src={chat_history_icon} height="24" width="24"/>
                </a> */}
                {/* <a href="" className="p-2" title="Transcript Download"><img src={transcript_icon} height="30" width="30" /></a> */}
            </td>
        </tr>
        )}                   
    </tbody>
    </table>
</div>
  )
}
