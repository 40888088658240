import axios from 'axios';
import React, { useState } from 'react'


export default function Subscription() {

    const [days, setDays] = useState("1");
    const [amount, setAmount] = useState("50");
    const [isChecked, setIsChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const jwt = localStorage.getItem('token');
    const header = {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    }
    const buy_subscription_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_BUY_SUBCRIPTION;

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const handleDaysChange = (event) => {
        const daysValue = parseInt(event.target.value, 10) || 1;  // Ensure it defaults to 0 if input is invalid
        console.log("DAYS", daysValue);
        setDays(daysValue);
        setAmount(daysValue * 50);  // Calculate amount based on days * 50
    };

    const information = {
        backgroundColor: "lightcyan",
        padding: "10px 5px"
    }
    const HandleBuySubscription = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        axios.post(buy_subscription_url,
            {
                "days": days
            }, header
        ).then(response => {               
            if (response.data.type === "OK" && response.data.url) {
                window.location.href = response.data.url;
            }
        }).catch(error => {
            setErrorMessage(error.response.data.message);
        });

    };

    return (
        <div>
            <div>
                <div className='container-fluid user-container border-top'>
                    <main id="main">

                        <section id="breadcrumbs" className="breadcrumbs">
                            <div className="container">
                                <ol>
                                    <li><a href="/app">Home</a></li>
                                    <li>Subscription</li>
                                </ol>
                            </div>
                        </section>

                        <section className="tg-may-account-wrapp tg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 detail">
                                        <div className="inner">
                                            <h2>Subscribe to boithok</h2>
                                            <p style={information}>
                                                To initiate and host a meeting, you have to purchase Boithok subscription. Subscription charge is flat 50 taka for 1 day. You can subscribe for as many days as you want. Fill the "Subscription for Days" field correctly with the number of days you want to subscribe.
                                            </p>
                                            <form className="tg-form" onSubmit={HandleBuySubscription}>
                                                {errorMessage &&
                                                    <div class="alert alert-danger alert-dismissible fade show">{errorMessage}
                                                        {/* <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span></button> */}
                                                    </div>
                                                }
                                                {successMessage &&
                                                    <div class="alert alert-success alert-dismissible fade show">{successMessage}
                                                        {/* <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span></button> */}
                                                    </div>
                                                }
                                                <div class="form-group row">
                                                    <label for="inputPassword" class="col-sm-3 col-form-label">Subscription for Day(s)</label>
                                                    <div class="col-sm-5">
                                                        <input type="number" className="form-control shadow-none" value={days}
                                                            onChange={handleDaysChange} placeholder="Enter day(s) for subscription" required />
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="inputPassword" class="col-sm-3 col-form-label">Total amount (Tk)</label>
                                                    <div class="col-sm-5">
                                                        <input type="text" className="form-control shadow-none" readOnly value={amount}
                                                            placeholder="Total Amount" required />
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>Your personal data will be used to process your order and for other purposes described in our <a href="/app/privacy_policy">privacy policy</a>. You can also read our <a href="/app/refund_policy">refund policy</a>.</p>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                        /> I have read and agree to the website <a href="/app/terms_and_conditions">terms and conditions</a>.
                                                    </label>
                                                </div>
                                                <br></br>
                                                <div class="form-group row">
                                                    <label for="button" class="col-sm-3 col-form-label"></label>
                                                    <div class="col-sm-5">
                                                        <button type="submit" className="btn btn-success" disabled={!isChecked}>Proceed for Payment<i className="fas fa-arrow-right" style={{ marginLeft: '5px' }}></i></button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>

                                    </div>
                                </div>
                                <div class="row"><img
                                    src={`${process.env.PUBLIC_URL}/assets/img/ssl-commerce.png`}
                                    alt='verifiy-ssl-commerz'
                                >
                                </img></div>
                            </div>

                        </section>
                    </main>
                </div>
            </div>
        </div>
    )
}
