import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import StartMeeting from './start_meeting';
import JoinMeeting from './join_meeting';
import Scheduler from './scheduler';
import UpcomingMeeting from './upcoming_meeting';
import MeetingHistory from './meeting_history';
import SubscriptionRequest from './subscription_request';

export default function MeetingDashboard() {

    const navigate = useNavigate();

    let jwt = localStorage.getItem('token');

    useEffect(() => {
        if (!jwt) {
            navigate("/login");
        }
    }, [jwt, navigate]);

    let activeSubscription = localStorage.getItem('activeSubscription');
    console.log("subs>>" + activeSubscription);
    let startMeetingClassName = activeSubscription === 'false' ? "nav-link" : "nav-link active";
    activeSubscription = activeSubscription === 'false' ? false : true;

    useEffect(() => {
        const handleTabShown = (event) => {
            const activeTabId = event.target.getAttribute('href');
            if (activeTabId === '#upcoming-meeting') {
                // Trigger a custom event on the window object
                const customEvent = new CustomEvent('loadUpcomingMeetings');
                window.dispatchEvent(customEvent);  // Dispatch to window
                console.log("component triggered");
            }
        };
    
        // Attach event listener to tab container
        const tabElement = document.getElementById('myTab');
        tabElement.addEventListener('shown.bs.tab', handleTabShown);
    
        // Cleanup listener on unmount
        return () => {
            tabElement.removeEventListener('shown.bs.tab', handleTabShown);
        };
    }, []);



    return (
        <div className='container-fluid user-container border-top'>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="/app">Home</a></li>
                        <li>Dashboard</li>
                    </ol>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <h4 className='my-4'>Meeting Dashboard</h4>
                        <div className="nav-area">
                            {/* <ul className="nav flex-column" id="myTab" role="tablist"> */}
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {!activeSubscription && (
                                    <li className="nav-item">
                                        <a className="nav-link active" id="subscription-tab" data-bs-toggle="tab" href="#buy-subscription" role="tab" aria-controls="subscription" aria-selected="true" ><i class="fas fa-play-circle"></i> <span>Subscription Info</span></a>
                                    </li>
                                )}
                                {activeSubscription && (
                                    <li className="nav-item">
                                        <a className={startMeetingClassName} id="start-meeting-tab" data-bs-toggle="tab" href="#start-meeting" role="tab" aria-controls="meeting-history" aria-selected="true" ><i class="fas fa-play-circle"></i> <span>Start Meeting</span></a>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <a className="nav-link" id="join-meeting-tab" data-bs-toggle="tab" href="#join-meeting" role="tab" aria-controls="address" aria-selected="false" ><i class="fas fa-video"></i> <span>Join Meeting</span></a>
                                </li>
                                {activeSubscription && (
                                    <>
                                        <li className="nav-item">
                                            <a className="nav-link" id="schedule-meetings-tab" data-bs-toggle="tab" href="#schedule-meeting" role="tab" aria-controls="address" aria-selected="false" ><i class="fas fa-calendar-alt"></i> <span>Schedule Meeting</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="change-meeting-tab" data-bs-toggle="tab" href="#upcoming-meeting" role="tab" aria-controls="address" aria-selected="false" ><i class="fas fa-clock"></i> <span>Upcoming Meeting</span></a>
                                        </li>
                                    </>
                                )}
                                <li className="nav-item">
                                    <a className="nav-link" id="history-tab" data-bs-toggle="tab" href="#meeting-history" role="tab" aria-controls="address" aria-selected="false" ><i class="fas fa-history"></i> <span>Meeting History</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="tab-content" id="myTabContent">
                        {!activeSubscription && (
                            <div className={`tab-pane fade pt-3 ${!activeSubscription ? "show active" : ""}`} id="buy-subscription" role="tabpanel" aria-labelledby="subscription">
                                <SubscriptionRequest />
                            </div>
                        )}
                        <div className={`tab-pane fade pt-3 ${activeSubscription ? "show active" : ""}`} id="start-meeting" role="tabpanel" aria-labelledby="my-order">
                            <StartMeeting />
                        </div>
                        <div className="tab-pane fade pt-3" id="join-meeting" role="tabpanel" aria-labelledby="my-address">
                            <JoinMeeting />
                        </div>
                        <div className="tab-pane fade pt-3" id="schedule-meeting" role="tabpanel" aria-labelledby="my-address">
                            <Scheduler />
                        </div>
                        <div className="tab-pane fade pt-3" id="upcoming-meeting" role="tabpanel" aria-labelledby="my-order">
                            <UpcomingMeeting />
                        </div>
                        <div className="tab-pane fade pt-3" id="meeting-history" role="tabpanel" aria-labelledby="my-order">
                            <MeetingHistory />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
