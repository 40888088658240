import React, { useState, useEffect } from 'react'
import axios from "axios";
import { spaceAfter4digit } from './date_time';
import { useNavigate } from "react-router-dom";
import Video_Conf from "../resources/male_avt.jpg";
import { getByDisplayValue } from '@testing-library/react';

export default function Profile() {
    const [flag, setFlag] = useState(true);

    //******************  User Profile information ************//

    const [image, setImage] = useState('');
    const [file, setFile] = useState('');
    const [meetingId, setMeetingId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [passcode, setPasscode] = useState('');
    const [invitationLink, setInvitationLink] = useState('');
    const [organization, setOrganization] = useState('');
    const [address, setAddress] = useState('');
    const [designation, setDesignation] = useState('');
    const [activeSubscription, setActiveSubscription] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);


    //*******************************************************//

    const navigate = useNavigate();
    const userInfoUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_INFO_URL;
    const userUpdateUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_UPDATE_URL;
    let jwt = localStorage.getItem('token');

    const header = {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    }

    useEffect(() => {
        axios.get(userInfoUrl, header)
            .then(response => {
                console.log("Success ====>  " + JSON.stringify(response, null, 2));
                setName(response.data.name);
                setEmail(response.data.email);
                setMobile(response.data.mobile);
                setPasscode(response.data.passCode);
                setInvitationLink(response.data.invitationLink);
                setMeetingId(response.data.personalMeetingID);
                setOrganization(response.data.organization);
                setDesignation(response.data.designation);
                setAddress(response.data.address);
                setImage(response.data.avatar);
                setActiveSubscription(response.data.activeSubscription);
                setSubscriptions(response.data.subscriptions);
                localStorage.setItem("designation", response.data.designation);
                localStorage.setItem("organization", response.data.organization);
            })
            .catch(error => {
                console.log("Request is not get any response");
                console.log("***************** Error Here : " + JSON.stringify(error, null, 2));
                if (error.response.status == "401") {
                    localStorage.removeItem("token");
                    navigate("/login");
                }
            })

    }, [])

    const handleProfileImage = (event) => {
        console.log(event.target.files[0]);
        const selectedImage = event.target.files[0];
        setFile(selectedImage);
        if (selectedImage) {
            const imageUrl = URL.createObjectURL(selectedImage);
            console.log("Image Selected");
            setImage(imageUrl);
        }
    }


    const handlProfileUpdate = async (event) => {
        event.preventDefault();
        console.log("Hello");
        let data = new FormData();
        data.append('mobile', mobile);
        data.append('organization', organization);
        data.append('designation', designation);
        data.append('address', address);
        data.append('file', file);

        const response = axios.post(userUpdateUrl,
            data, header)
            .then(response => {
                console.log("Response data : " + JSON.stringify(response, null, 2));
                setFlag(true);
            }).catch(error => {
                console.error("Error in registration + " + JSON.stringify(error, null, 2));

                // if(error.response.status == "400"){
                //     setMessage("This user already registered");
                // }
                // else if(error.response.status == null){
                //     setMessage("Server unreachable");
                // }
                // else if(error.data.status === "401")  {
                //     navigate("/login");
                // }
                console.error("Error code " + error.response.status);
            })

    }

    const imgCenterStyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto"
    };

    return (
        <div className='container-fluid user-container border-top'>
            <main id="main">
                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li>Update Profile</li>
                        </ol>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div class="my-4 d-flex justify-content-between align-items-center">
                                <h4>Update Profile</h4>
                                <div>
                                    {flag && <button className='btn btn-primary' onClick={() => setFlag(false)}>Edit</button>}
                                    {!flag && <button className='btn btn-success' onClick={() => setFlag(true)}>Cancel</button>}
                                </div>
                            </div>

                            <div className="address-form">
                                <div className="inner">
                                    {flag &&
                                        <>
                                            <div class="card border-light ">
                                                <div class="card-header">Personal Info</div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 d-flex flex-wrap">
                                                            <div class="col-md-8">
                                                                <div class="col-12 col-md-12 d-flex flex-wrap">
                                                                    <div class=" col-12 col-md-4">
                                                                        <div class="form-label"><strong>Email</strong></div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{email}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label"><strong>Mobile</strong></div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{mobile}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label"><strong>Personal Meeting ID</strong></div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{spaceAfter4digit(meetingId)}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label"><strong>Passcode</strong></div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{passcode}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label"><strong>Invitation Link</strong></div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{invitationLink}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="col-md-12 col-sm-12 p-2">
                                                                    <figure className="text-center">
                                                                        <img src={image} style={imgCenterStyle} className="img-round" alt="Profile" width="150px" />
                                                                        <figcaption className="mt-2">Profile Pic</figcaption>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card border-light">
                                                <div class="card-header">Professional Info</div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-12 d-flex">
                                                            <div class="col-md-8">
                                                                <div class="col-12 col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label"><strong>Organization</strong></div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{organization}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label"><strong>Designation</strong></div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{designation}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label"><strong>Address</strong></div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{address}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card border-light">
                                                <div class="card-header">Subscription Info</div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-12 d-flex ">
                                                            <div class="col-md-8">
                                                                {subscriptions.map((subscription, index) => (
                                                                    <li key={subscription.id || index}>
                                                                        <h3>{subscription.days}</h3>
                                                                        <p>Status: {subscription.status}</p>
                                                                        <p>Start Date: {subscription.startDate}</p>
                                                                        <p>End Date: {subscription.expireAt}</p>
                                                                    </li>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {!flag &&
                                        <form>
                                            <div class="card border-light ">
                                                <div class="card-header">Personal Info</div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 d-flex flex-wrap">
                                                            <div class="col-md-8">
                                                                <div class="col-12 col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Email</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{email}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Mobile</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <input type='text' name='mobile' className='form-label form-control shadow-none'
                                                                            defaultValue={mobile} onChange={(e) => setMobile(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Personal Meeting ID</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{spaceAfter4digit(meetingId)}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Passcode</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{passcode}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Invitation Link</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{invitationLink}</div>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div class="col-md-4">
                                                                <div class="d-flex flex-column justify-content-center align-items-center" style={{ height: '220px' }}>
                                                                    {image ?
                                                                        <img src={image} className='img-round ' width="150px" />
                                                                        : <img src="https://via.placeholder.com/100" alt="Placeholder Image" />}
                                                                    <p className="mt-2">Profile Pic</p>
                                                                    <input type='file' name='file' className="pt-4 mt-3" onChange={handleProfileImage} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card border-light">
                                                <div class="card-header">Professional Info</div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-12 d-flex">
                                                            <div class="col-md-8">
                                                                <div class="col-12 col-md-12 d-flex mb-2 flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Organization</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <input type='text' name='organization' className='form-control shadow-none'
                                                                            defaultValue={organization} onChange={(e) => setOrganization(e.target.value)} />

                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 d-flex mb-2 flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Designation</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <input type='text' name='designation' className='form-control shadow-none'
                                                                            defaultValue={designation} onChange={(e) => setDesignation(e.target.value)} />

                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 d-flex mb-2 flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Address</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <input type='text' name='address' className='form-control shadow-none'
                                                                            defaultValue={address} onChange={(e) => setAddress(e.target.value)} />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card border-light">
                                                <div class="card-header">Subscription Info</div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-12 d-flex">
                                                            <div class="col-md-8">

                                                                {subscriptions.map((subscription, index) => (
                                                                    <li key={subscription.id || index}>
                                                                        <h3>{subscription.days}</h3>
                                                                        <p>Status: {subscription.status}</p>
                                                                        <p>Start Date: {subscription.startDate}</p>
                                                                        <p>End Date: {subscription.expireAt}</p>
                                                                    </li>
                                                                ))}


                                                                {/* <div class="col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Subscription Name</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="col-sm-12">{activePackage.packageName}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">Start Time</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="col-sm-12">{new Date(activePackage.startTime).toLocaleString()}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 d-flex flex-wrap">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-label">End Time</div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <div class="form-label">{new Date(activePackage.endTime).toLocaleString()}</div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-3 col-sm-12 p-2'>
                                                <button className='btn btn-primary' onClick={() => setFlag(true)} >Cancel</button>
                                                <button type='submit' className='btn btn-success btn-block ms-2' onClick={handlProfileUpdate}>Save</button>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div >
    )
}
