import React from 'react'

export default function About() {
    const listyle = {
        color: "#3F8755"
    }
    return (
        <>
            <div className='container-fluid user-container border-top'>
                <main id="main">
                    <section id="breadcrumbs" className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="/">Home</a></li>
                                <li>About</li>
                            </ol>
                        </div>
                    </section>

                    <section className="container-fluid">
                        <div class="container ">
                            <div class="row gx-5">
                                <h2 class="">About Boithok</h2>
                                {/* <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="RotateMoveLeft">                                    
                                        <img src="assets/img/wsis-cert.jpeg" class="img-fluid w-100" alt="" />
                                    </div>
                                </div> */}
                                <div class="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
                                    {/* <h4 class="mb-1 text-primary">About Boithok</h4> */}
                                    <p class="mb-4 text-justify">Boithok is a feature-riched web-based video conferencing platform hosted at the National Data Center. It enables users to share their needs and thoughts through secure, real-time video conferencing, creating a realistic virtual meeting experience. The platform ensures complete security and confidentiality of user data, with no data or recordings stored outside the country. Boithok is also highly user-friendly, allowing users to join meetings effortlessly by clicking a meeting link within seconds.
                                    </p>
                                    {/* <ul class="no-bullets">
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  Unlimited meeting durations, providing uninterrupted sessions.</li>
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  Hosted securely in the National Data Center.</li>
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  Recordings are saved safely, can be downloaded at any time.</li>
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  All necessary features for a video conferencing are available.</li>
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  No registration required for users to join meetings.</li>
                                    </ul> */}
                                </div>
                            </div>
                            <div class="row gx-5">
                                
                                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                                    <p class="mb-4 text-justify">Boithok won the prestigious World Summit on Information Society (WSIS) Award 2024 in the category of "Building confidence and security in the use of ICTs" from the United Nations, one of the top honors in the international ICT sector. 
                                    </p>
                                    <p>Key benefits of Boithok at a glance:</p>
                                    <ul class="no-bullets">
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  Unlimited meeting durations, providing uninterrupted sessions.</li>
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  Hosted securely in the National Data Center.</li>
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  Recordings are saved safely, can be downloaded at any time.</li>
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  All necessary features for a video conferencing are available.</li>
                                        <li><i class="fas fa-check-circle" style={listyle}></i>  No registration required for users to join meetings.</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="RotateMoveLeft">
                                        <img src="assets/img/wsis-cert.jpeg" class="img-fluid w-100" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="row g-5">
                                <div> The Chief Adviser’s Office, Cabinet Division, Ministry of Foreign Affairs, ICT Division, BCC, A2I, District Offices, and several other government offices regularly use Boithok. To date, the platform has successfully hosted over 2,500 meetings.
                                </div>
                            </div>

                            <div className="services row g-5">
                                <div className="section-title" data-aos="fade-up">
                                    <h2>Features</h2>
                                    <p>Boithok is a feature-riched video conferencing solution that helps you to stay connected & collaboration with team members securely and efficiently</p>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
                                        <div className="icon-box icon-box-pink">
                                            <div className="icon"><i className="bx bxl-dribbble"></i></div>
                                            <h4 className="title"><a href="">High-Quality Video</a></h4>
                                            <p className="description">Experience crystal-clear video and audio for your meetings, presentations, and more.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="100">
                                        <div className="icon-box icon-box-cyan">
                                            <div className="icon"><i className="bx bx-file"></i></div>
                                            <h4 className="title"><a href="">Collaboration</a></h4>
                                            <p className="description">Easily collaborate with your team members, share content, and work together in real-time.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                                        <div className="icon-box icon-box-green">
                                            <div className="icon"><i className="bx bx-tachometer"></i></div>
                                            <h4 className="title"><a href="">Secure and Private</a></h4>
                                            <p className="description">We prioritize the security of your conversations with top-notch encryption and privacy measures.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                                        <div className="icon-box icon-box-blue">
                                            <div className="icon"><i className="bx bx-mobile-alt"></i></div>
                                            <h4 className="title"><a href="">Mobile Apps</a></h4>
                                            <p className="description">Stay connected on the go with our feature-rich mobile apps for iOS and Android.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                                        <div className="icon-box icon-box-blue">
                                            <div className="icon"><i className="bx bx-video-recording"></i></div>
                                            <h4 className="title"><a href="">Recording</a></h4>
                                            <p className="description">Record your meetings and webinars for future reference or sharing with others..</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                                        <div className="icon-box icon-box-green">
                                            <div className="icon"><i className="bx bx-share"></i></div>
                                            <h4 className="title"><a href="">Screen Sharing</a></h4>
                                            <p className="description">Share your screen during meetings and presentations for effective communication.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                                        <div className="icon-box icon-box-cyan">
                                            <div className="icon"><i className="bx bx-group"></i></div>
                                            <h4 className="title"><a href="">Webinars Hosting</a></h4>
                                            <p className="description">Host webinars with ease, engage with your audience, and conduct Q&A sessions..</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                                        <div className="icon-box icon-box-pink">
                                            <div className="icon"><i className="bx bx-list-check"></i></div>
                                            <h4 className="title"><a href="">Attendance</a></h4>
                                            <p className="description">Get the Participants list of each meeting..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </>
    );
}