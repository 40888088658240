import React from 'react'

export default function FAQ() {
    const listyle = {
        color: "#3F8755"
    }
    return (
        <>
            <div className='container-fluid user-container border-top'>
                <main id="main">
                    <section id="breadcrumbs" className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="/">Home</a></li>
                                <li>FAQ</li>
                            </ol>
                        </div>
                    </section>

                    <section id="faq" className="faq">
                        <div className="container">

                            {/* <div  data-aos="fade-up"> */}
                                <h2>Frequently Asked Questions</h2>
                            {/* </div> */}

                            <ul className="faq-list">

                                <li>
                                    <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">How can I start or host a meeting? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                        Only registered users can host meetings in Boithok. You can register through the Boithok website.
                                        After logging in, you can start a meeting from the homepage using the "Start Meeting" button or from the dashboard.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">Do participants need to register to join the meeting? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                        No, Participants need not to register in the meeting to join. They can join through the invitation link or using meeting & passcode from Join Meeting button in Boithok homepage.
                                        </p>
                                    </div>
                                </li>

                                
                                <li>
                                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question"> How can a moderator download the recording of a meeting from Boithok? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                        After logging in Boithok website, moderator will find meeting history in the dashboad. There he/she can search and download recording for a particular meeting.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq4" className="collapsed question"> How can a moderator control whether anyone can join or not? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            A moderator will have a button in the toolbox named “More Actions”. After clicking the button, he will be shown a menu list including “Security Options” as a menu. In this menu, he will be able to enable the lobby or add password to the meeting. After enabling the lobby or the password, every participant can not join without the moderator's consent or entering the password set by the moderator for the meeting.
                                        </p>
                                    </div>
                                </li>

                                {/* <li>
                                    <div data-bs-toggle="collapse" href="#faq5" className="collapsed question">What are the differences between “Desktop App” and “Browser-based Boithok” ? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            During sharing-screen in presentation view in web-browser, to access toolbar options, one has to switch off the presentation mode and go to the browser to access toolbox options. Whereas in the Desktop app, one will get the toolbox options in the presentation window. So sharing screen is comparatively easier in Desktop app.
                                        </p>
                                    </div>
                                </li> */}

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq6" className="collapsed question">How can one share his/her screen in the presentation view ? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            To present a slide or a pdf in the presentation view, first one has to open the file in presentation mode with a corresponding application like (Powerpoint or PdfReader). Then using “Alt + Tab” , he has to switch to web browser. In Boithok share screen option, he will get his intended application with presentation mode. Choosing this option, he will be able to share the intended application. To stop sharing, he can press “Esc” button.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq7" className="collapsed question">What are the procedures one should follow to mitigate the issue of camera and speaker? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            The main point is that the camera and speaker should be connected with your system. There will be a pop up when someone will enter the meeting questioning whether he wants to allow camera and speaker or block. If someone blocks the camera and speaker, he will get “View Site Information” icon(the one with the lock shape) in the URL bar for this site where he can allow the camera and speaker. If all are in right position but still one is facing problems , he can reconnect the camera and the speaker or rejoin the meeting.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq8" className="collapsed question">How can a moderator get the attendance list of the meeting?  <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq8" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            There is a button at the top of the meeting page to get the participant list. After clicking the button, a pop up will be opened. The moderator will get a button named “Download Participant List” button. Just clicking the button will download the attendance list or redirect to the attendance page from where he can download. One other option is to click the “Leave The Meeting” which will end-meeting pop up. There the moderator will also get the “Download Participant List” Button. Also, moderator will be able to download from meeting history from boithok website.
                                        </p>
                                    </div>
                                </li>
                                {/* <li>
                                    <div data-bs-toggle="collapse" href="#faq9" className="collapsed question"> How can I get credentials (username & password) for hosting a meeting? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq9" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            You can register from this Boithok website and request for Host Credentials. Go to register form and give your information. After verifying, Boithok Team will contact you on your provided Email and Phone number.
                                        </p>
                                    </div>
                                </li> */}


                            </ul>

                        </div>
                    </section>


                </main>
            </div>
        </>
    );
}