import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import delete_icon from "../resources/delete_icon.png";
import edit_icon from "../resources/edit_icon.png";
import mail_invitation_icon from "../resources/mail_invitation_icon.png";
import calender_noti_icon from "../resources/calender_noti_icon.png";
import copy_icon from "../resources/copy_icon.png";
import { Modal, Button } from 'react-bootstrap';
// import {GoogleCalenderNotification} from './google_calendar_notification.js'

export default function UpcomingMeeting() {
    const [meetingData, setMeetingData] = useState([]);
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    
    // const [mailList, setMailList] = useState([]);

    let [deleteFlag, setDeleteFlag] = useState(false);
    let [showModal, setShowModal] = useState(false);
    let [tempItem, setTempItem] = useState("");
    let [email, setEmail] = useState();
    const [validEmails, setValidEmails] = useState([]);
    let [body, setBody] = useState();


    const accessToken = localStorage.getItem('access_token');
    const expiresIn = localStorage.getItem('expires_in');
    const currentDate = new Date().toISOString().slice(0, 10);
  
    const navigate = useNavigate();
    const meetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CREATE_UPCOMING_MEETING_URL;
    const updateUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_UPCOMING_MEETING_URL;
    const startMeetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_START_MEETING

    const emailInvitaitonUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EMAIL_INVITATION;
    let deleteUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DELETE_UPCOMING_MEETING_URL;
    let jwt = localStorage.getItem('token');

    const header = {
        headers : {
            'Authorization' : 'Bearer '+ jwt
        }
    }    
    

    useEffect(() => {   
        console.log("Hello there");     
        axios.get(meetingUrl, header)
        .then(response =>{
            console.log("Success ====>  " + JSON.stringify(response , null, 2));            
            setMeetingData(response.data);
        })
        .catch(error =>{
            console.log("Request is not get any response");
            console.log( "***************** Error Here : "+ JSON.stringify(error , null, 2));  
            if(error.response.status == "401")  {
                localStorage.removeItem("token");
                navigate("/login");
            }
        })

      
    }, [deleteFlag])

    useEffect(() =>{
        console.log("Mail List : " + validEmails);
    },[validEmails])

    

   
    const invitationEmailBodyPreparation=(item)=>{
        const subject= "Subject: Invitation for Virtual Meeting on "+ item.title +"\n\n";
        const intro = "Dear Concern, \n\nI hope this email finds you well. We are excited to invite you to participate in our upcoming virtual meeting\n"
        const date = "\nDate and Time: "+ item.meetingDate + ","+item.startTime + " - "+ item.endTime +"\n\n";
        const agenda = "Agenda: \n1. Welcome and Introduction \n2. Presentation on "+ item.title +" \n3. Q&A Session \n4. Closing Remarks"
        const meetingDetails = "\n\nVirtual Meeting Details: \n- Platform: Boithok \n- Meeting Link: " + "<a href=" + item.invitationLink + ">" + item.invitationLink + "</a>" +" \n- Meeting ID : " + item.meetingID + " \n- Passcode : "+ item.passcode + " \n\nWe believe that your insights and contributions will greatly enrich our discussions during the meeting. Your presence is highly valued. \n\nWe look forward to your participation.";
        const sender = "\n\nBest regards\n" + localStorage.getItem('name') + "\n" + localStorage.getItem('designation') + "\n" + localStorage.getItem('organization');
        const emailBody = subject+intro+date+agenda+meetingDetails+sender;
        console.log(emailBody);
        setBody(emailBody);
        return emailBody;
    }

    const sendMeetingInvitationByEmail =()=>{
        console.log(emailInvitaitonUrl);
        console.log("validEmails " +validEmails);
        
        body = body.replace(/\n/g, '<br>');
        console.log("body " + body);
        console.log("mailList " + validEmails);

        axios.post(emailInvitaitonUrl, 
            {
                mailList:validEmails,
                body: body
            }, header)
            .then(response =>{
                console.log("Response data : " + JSON.stringify(response, null, 2));    
                setShowModal(true);

            }).catch (error => {
                console.error("Error in sending Email + " + JSON.stringify(error, null, 2));                
                // if(error.response.status == "400"){
                //     setMessage("This user already registered");
                // }
                // else if(error.response.status == null){
                //     setMessage("Server unreachable");
                // }
                // else if(error.data.status === "401")  {
                //     navigate("/login");
                // }
                console.error("Error code " + error.response.status);
            })   
            setValidEmails([]);
    }
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copyInvitationLink = (item) =>{
        console.log("Test");
        let copyInvitL = " Meeting Id : " + item.meetingID + "\n\n Passcode : " + item.passcode + "\n\n Invitation Link : " + item.invitationLink;
        navigator.clipboard.writeText(copyInvitL);
        console.log(copyInvitL); 
        toast.success('Meeting info copied', { autoClose: 2000 });
    }

    const deleteMeeting = () =>{
        deleteUrl = deleteUrl + tempItem.id;
        console.log("Delete URL " + deleteUrl);
        axios.delete(deleteUrl, header)
        .then(response => {
            console.log("Success ====>  " + JSON.stringify(response , null, 2));
            setDeleteFlag(!deleteFlag);
        })
        .catch(error => {
            console.log("Request is not get any response");
            console.log( "***************** Error Here : "+ JSON.stringify(error , null, 2));  
            if(error.response.status == "401")  {
                localStorage.removeItem("token");
                navigate("/login");
            }
        })
    }

    const editMeeting = () => {
        // event.preventDefault();
        console.log("editMeeting " +tempItem.title);
        axios.put(updateUrl,tempItem, header)
        .then(response => {
            console.log("Success ====>  " + JSON.stringify(response , null, 2));            
            setDeleteFlag(!deleteFlag);
            // document.getElementById('editMeetingModal').modal('hide');
            
        })
        .catch(error =>{
            console.log("Request is not get any response");
            console.log( "***************** Error Here : "+ JSON.stringify(error , null, 2));  
            if(error.response.status == "401")  {
                localStorage.removeItem("token");
                navigate("/login");
            }
        })
        console.log("editMeeting " +tempItem.title);
    }

    const startMeeting = (e) =>{        
        console.log("header " + JSON.stringify(header));
        const meetingUrl = e;
        console.log("meetingUrl : " + meetingUrl);
        axios.get(meetingUrl, header)                
        .then(response => {                
            console.log( " Response : "+ JSON.stringify(response));
            console.log( " Response : "+ response.data.url);
            window.open(response.data.url, "_blank");
        }).catch( error => {
            console.log( " Error Here : "+ JSON.stringify(error , null, 2));  
            console.log(error.response.status);
            if(error.response.status == "401")  {
                localStorage.removeItem("token");
                navigate("/login");
            }
            
        })      
}

    const searchUpcomingMeetingListByDate = (event) =>{
        event.preventDefault();  
        let url = meetingUrl + "/range?start=" + start + "&end=" + end;
        console.log("url " + url);
        axios.get(
            url, header
        ).then(response =>{
            console.log("Success ====>  " + JSON.stringify(response , null, 2));            
            setMeetingData(response.data);
        })
        .catch(error =>{
            console.log("Request is not get any response");
            console.log( "***************** Error Here : "+ JSON.stringify(error , null, 2));  
            if(error.response.status == "401")  {
                localStorage.removeItem("token");
                navigate("/login");
            }
        })
    }

    const handleKeyDown = (event) => {
        if(event.key === ' '){
            console.log('Test-1');
            validateAndStoreEmail();
        }
    }

    const validateAndStoreEmail = () => {
        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (emailRegex.test(email)) {
          // Valid email, store it
          setValidEmails((prevEmails) => ([...prevEmails, email]));
          setEmail(''); // Clear the input field after storing
          console.log(validEmails);
        } else {
          // Invalid email, show error or handle accordingly
          console.log('Invalid email address');
        }
        console.log(validEmails);
    };
    const removeEmail = (indexToRemove) => {
        const updatedEmails = validEmails.filter((_, index) => index !== indexToRemove);
        setValidEmails(updatedEmails);
    };

    const formatDate = (date) =>{
        const dateObject = new Date(date);

        // Extract year, month, and day components
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(dateObject.getDate()).padStart(2, '0');

        // Create the formatted date string in "dd-mm-yyyy" format
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }

    const convertToAMPM = (time24)=>{
        // Parse the time string to a Date object
        const time = new Date(`1970-01-01T${time24}`);
      
        // Get hours and minutes
        let hours = time.getHours();
        const minutes = time.getMinutes();
      
        // Determine AM/PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
      
        // Convert hours to 12-hour format
        hours = hours % 12;
        hours = hours || 12; // If hours is 0, set it to 12
      
        // Add leading zero to minutes if needed
        const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
      
        // Create the formatted time string
        const formattedTime = `${hours}:${minutesString} ${ampm}`;
      
        return formattedTime;
      }

    const setGoogleCalendarNotification = (item) =>{
        console.log("Hello world");
        // GoogleCalenderNotification(item);
    }


  return (
    <div className="container mt-4">
         <form onSubmit={searchUpcomingMeetingListByDate}>
                <div className="row">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <h4>Upcoming Meeting</h4>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-flex flex-wrap align-items-center justify-content-end">
                            <div className="p-2 inlineDisply">
                                <label className="me-2 ">Start</label>
                                <input
                                    type="date"
                                    name="start"
                                    onChange={(e) => setStart(e.target.value)}
                                    min={currentDate}
                                    required
                                    className="form-control"
                                />
                            </div>
                            <div className="p-2 inlineDisply">
                                <label className="me-2">End</label>
                                <input
                                    type="date"
                                    name="end"
                                    onChange={(e) => setEnd(e.target.value)}
                                    min={currentDate}
                                    required
                                    className="form-control"
                                />
                            </div>
                            <div className="p-2 text-right">
                                <label className="me-2"></label>
                                <input type="submit" value="Search" className="btn btn-primary form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
    
    
<table className="table table-bordered custom-table">
    <thead>
        <tr>
            <th>Sl. no.</th>
            <th>Meeting ID</th>
            <th>Passcode</th>
            <th>Meeting Title</th>                    
            <th>Meeting Date</th>                    
            <th>Start Time</th> 
            <th>End Time</th> 
            <th>Start Now</th> 
            <th>Actions</th>                                        
        </tr>
    </thead>
    <ToastContainer />
    <tbody>
        {meetingData.map((item, index) => 
        <tr>
            <td>{index+1}</td>
            <td>{item.meetingID}</td>
            <td>{item.passcode}</td>
            <td>{item.title}</td>                    
            <td>{formatDate(item.meetingDate)}</td>
            <td>{convertToAMPM(item.startTime)}</td>
            <td>{convertToAMPM(item.endTime)}</td>
            <td>
                <button className="btn btn-primary" onClick={() => startMeeting(startMeetingUrl + "/" + item.meetingID)}>Start Meeting</button>                        
            </td>                    
            <td>
                
                <Link to="" title="Copy Meeting Info" className="p-2">
                    <img src={copy_icon} width="25" height="25" 
                        onClick= {() => copyInvitationLink(item) }>
                    </img> 
                </Link>
                <Link to="" title="Invite through email" className="p-2">
                    <img src={mail_invitation_icon} width="25" height="25" data-bs-toggle="modal" data-bs-target="#inviteForMeeting"
                    onClick={() => invitationEmailBodyPreparation(item)}></img> 

                </Link>

                {/* <Link to="" title="Set Calender Notification" className="p-2">
                    <img src={calender_noti_icon} width="25" height="25" onClick={() => setGoogleCalendarNotification(item)} ></img> 
                </Link> */}

                <Link to="" title="Edit meeting" className="p-2" >
                    <img src={edit_icon} width="25" height="25" data-bs-toggle="modal" data-bs-target="#editMeetingModal" 
                    onClick={() => setTempItem(item)}></img>
                </Link> 

                <Link to="" title="Delete meeting" className="p-2">
                    <img src={delete_icon} width="25" height="25" data-bs-toggle="modal" data-bs-target="#deleteMeetingModal" 
                    onClick={() => setTempItem(item)} ></img>
                </Link>                        
            </td>
        </tr>
        )}             
    </tbody>
    </table>
    
    <div className="modal fade" id="deleteMeetingModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Delete meeting</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body invitaion-info">
                    <p><strong> Are you sure want to delete meeting?</strong></p>
                    <p>Meeting Title : {tempItem.title}</p>
                    <p>Meeting ID : {tempItem.meetingID}</p>
                    
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={() => deleteMeeting()} data-bs-dismiss="modal">Delete Meeting</button>
                </div>
                </div>
            </div>
    </div>

     
     <div className="modal fade" id="editMeetingModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Edit meeting</h5>
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {/* <form onSubmit={editMeeting}> */}
                    <div className="modal-body invitaion-info">
                    
                        <div className="inlineDisply">
                            <label className="labelWidth">Passcode</label>                             
                            <input type="text" className="form-control inputWidth shadow-none"
                            id="passcode"  placeholder="Passcode"
                            onChange={(e) => setTempItem({...tempItem, passcode : e.target.value})}
                            value={tempItem.passcode}
                            />
                        </div>

                        <div className="inlineDisply">
                            <label className="labelWidth">Meeting Title</label>   

                            <input type="text" className="form-control inputWidth shadow-none" id="title"  
                            placeholder="Meeting Title" 
                            onChange={(e) => setTempItem({...tempItem, title : e.target.value})}
                            value={tempItem.title}/>
                        </div>

                        <div className="inlineDisply">
                            <label className="labelWidth">Select a Date</label>                            
                            <input type="date" className="form-control inputWidth shadow-none" 
                            id="dateInput" 
                            onChange={(e) => setTempItem({...tempItem, meetingDate : e.target.value})}
                            value={tempItem.meetingDate}/>                                
                        </div>

                        <div className="inlineDisply">
                            <label className="labelWidth">Meeting Start Time</label>                            
                            <input type="time" className="form-control inputWidth shadow-none" 
                            onChange={(e) => setTempItem({...tempItem, startTime : e.target.value})}
                            value={tempItem.startTime}/>                                
                        </div>
                        <div className="inlineDisply">
                            <label className="labelWidth">Meeting End Time</label>                
                            <input type="time" className="form-control inputWidth shadow-none" id="endTimeInput" 
                            onChange={(e) => setTempItem({...tempItem, endTime : e.target.value})}
                            value={tempItem.endTime}/>
                        
                        </div>

                    
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={() => editMeeting()} data-bs-dismiss="modal">Save Meeting</button>
                    </div>
                </div>
            </div>
        </div>
    

        <div className="modal fade" id="inviteForMeeting" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Meeting Invitation By Email</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body invitaion-info">
                    <p>Enter valid email address</p>
                    <div className="inlineDisply">
                            <input type="email" className="form-control shadow-none" placeholder="Enter email address" value={email}
                            onChange={ (e) => setEmail(e.target.value)} onKeyDown={handleKeyDown}/>                                                                    
                    </div>
                    <ul>
                        {
                            validEmails.map((validEmail, index) =>(                                        
                                <li className="email-deco" key={index}>{validEmail} <button className="" onClick={() => removeEmail(index)}>x</button></li>                                        
                            ))
                        }
                    </ul>    
                    <div className="inlineDisply">
                        <textarea id="emailAddresses" name="emailAddresses" rows="15" cols="60" value={body}
                        onChange = {(e)=> setBody(e.target.value)} >
                        
                        </textarea>
                    </div>
                                            
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={() => sendMeetingInvitationByEmail()} data-bs-dismiss="modal">Send Invitation</button>
                </div>
                </div>
            </div>
     </div>

     <div className="modal fade" id="mailSentSuccess" role="dialog" aria-labelledby="mailSentSuccess" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Ok</button>                            
                    </div>
                </div>
            </div>
     </div>
     <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
            <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Email Invitaion sent successful.
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleCloseModal}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>

</div>
  )
}
