import axios from 'axios';
import React, { useState } from 'react'


export default function PrivacyPolicy() {


return (
<div>
    <div>
        <div className='container-fluid user-container border-top'>
            <main id="main">

                <section id="breadcrumbs" className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/app">Home</a></li>
                            <li>Terms & Conditions</li>
                        </ol>
                    </div>
                </section>

                <section className="tg-may-account-wrapp tg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 detail">
                                <div className="inner">
                                    <h2>Privacy Policy</h2>
                                    <h4>Who we are</h4>
                                    <p>Boithok is owned and operated by Bangladesh Government’s e-Government Computer
                                        Incident Response Team (BGD e-GOV CIRT), Bangladesh Computer Council (BCC).
                                        Boithok (‘We’, ‘Our’ or ‘Us’) is committed to protecting your privacy. Our
                                        policy is to respect your privacy and comply with applicable laws and
                                        regulations regarding any personal information we may collect about you. This
                                        Privacy Policy explains how your personal information is collected, used, and
                                        (under certain conditions) disclosed by Boithok. It also explains the steps
                                        taken to secure and protect your personal information. This Privacy Policy
                                        applies to our website, https://vc.bcc.gov.bd/ (‘website’, ‘the Site’ or ‘our
                                        Site’) and its associated subdomains. This policy is effective as of December
                                        2022.</p>
                                    <h4>Information We Collect</h4>
                                    <p>We collect information from you when you place an order, subscribe to our
                                        newsletter, respond to a survey or fill out a form. We may collect the following
                                        pieces of information if you seek to place an order for a product or service
                                        with us on the Site: 1. Full Name 2. Email address(es) 3. Mobile Numbers We
                                        collect and store these data for processing your purchase orders on the Site and
                                        any possible later claims, and to provide you with the services and information
                                        offered through our website and which you request.</p>
                                    <h4>How Do We Use the Information We Collect</h4>
                                    <p>Any of the information we collect from you may be used in the following ways: 1.
                                        To personalize your experience. 2. To improve our service. We continually try to
                                        improve our service based on customer information and feedback. 3. To improve
                                        customer service. 4. To process transactions. 5. To send emails.</p>
                                    <h4>Log Data</h4>
                                    <p>When you visit our website, our servers may automatically log the standard data
                                        provided by your web browser. It may include your device’s Internet Protocol
                                        (IP) address, your browser type and version, the pages you visit, the time and
                                        date of your visit, the time spent on each page, other details about your visit,
                                        and technical details that occur in conjunction with any errors, you may
                                        encounter. Please be aware that while this information may not be personally
                                        identifying by itself, it may be possible to combine it with other data to
                                        personally identify individual persons.</p>
                                    <h4>Collection and Use of Information</h4>
                                    <p>We may collect personal information from you when you do any of the following on
                                        our website: 1. Use a mobile device or web browser to access our content 2.
                                        Contact us via email, social media, or on any similar technologies 3. When you
                                        mention us on social media We may collect, hold, use, and disclose information
                                        for the following purposes, and personal information will not be further
                                        processed in a manner that is incompatible with these purposes: 1. To contact
                                        and communicate with you 2. To enable you to access and use our website,
                                        associated applications, and associated social media platforms Please be aware
                                        that we may combine information we collect about you with general information or
                                        research data we receive from other trusted sources.</p>
                                    <h4>Cookies</h4>
                                    <p>We use “cookies” to collect information about you and your activity across our
                                        site. A cookie is a small amount of data in tiny text files generated by
                                        websites and saved by your web browser. It is accessed each time you visit our
                                        site and helps us serve you content based on preferences you have specified,
                                        such as language or login information.</p>
                                    <h4>Embedded content from other websites</h4>
                                    <p>We may include, display or make available third-party content (data, information,
                                        applications, and other services) and provide links to other websites or
                                        services. Please note we do not take responsibility for any third-party
                                        services, including their accuracy, timeliness, validity, copyright compliance,
                                        legality, quality, or any aspect thereof. We do not assume and shall not have
                                        any liability to you or any other entity for any third-party content. Content,
                                        links, and service from other websites are provided solely for your convenience
                                        and you access and use them entirely at your own risk and discretion.</p>
                                    <h4>Who we share your data with</h4>
                                    <p>We may disclose personal information to: 1. A parent or affiliate of our website.
                                        2. Our employees and consultants. 3. IT service providers, data storage, hosting
                                        and server providers, advertisers, or analytics platforms. 4. Courts, regulatory
                                        authorities, and law enforcement agencies, as required by law, in connection
                                        with any actual or prospective legal proceedings, or in order to establish,
                                        exercise, or defend our legal rights. We may engage trusted third-party service
                                        providers to perform functions for us, such as hosting and maintaining our
                                        servers, database storage, e-mail management, payment card processing for
                                        fulfilling orders for products and services purchased through our Site. We may
                                        share portions of our log file data, including IP addresses for web analytics
                                        and application development. Your IP address may be used to estimate general
                                        technographic such as connection speed, type of device used, whether visited
                                        from a shared network, etc. These analytics are generally used in IT audits and
                                        research.</p>
                                    <h4>How long we retain your data</h4>
                                    <p>We retain your data so long as we need to provide service to you and fulfill the
                                        purposes described in this policy. This is also the case for anyone that we
                                        share your information and who carries out services on our behalf. When your
                                        information is no longer needed for complying with legal and regulatory
                                        obligations, we either remove it from our system or anonymize it. However, we
                                        may retain your information for compliance with legal, accounting, reporting, or
                                        IT audit obligation, for archiving, or for research or statistical purposes. The
                                        time period may depend on what we are using your information for, in accordance
                                        with this privacy policy.</p>
                                    <h4>Security of Your Information / How We Protect Your Data</h4>
                                    <p>While we collect, process, and retain your information, we are bound to protect
                                        it within technically acceptable means to prevent loss and theft, as well as
                                        unauthorized access, disclosure, copying, use, or modification. We implement a
                                        variety of security measures to maintain the safety of your information when you
                                        visit, place an order or submit a form on our Site. We employ the use of a
                                        secured server with firewalls. All information is transmitted via TLS/SSL
                                        technology and then encrypted into our Payment gateway providers’ the database
                                        only to be accessible by those with authorized special access rights to such
                                        systems and are required to keep the data confidential. However, we advise that
                                        no method of electronic transmission or storage is 100% secure, hence we cannot
                                        ensure or warrant the absolute security of information. We will comply with laws
                                        applicable to us regarding any breach of our technical, physical, or managerial
                                        safeguards. You are responsible for selecting any password and its overall
                                        security strength and also against unauthorized access to your computer/device,
                                        thus ensuring the security of your own information within the bounds of our
                                        services.</p>
                                    <h4>What rights you have over your data</h4>
                                    <p>You always retain the right to withhold personal information from us, with the
                                        understanding that your experience of our website may be affected. We will not
                                        discriminate against you for exercising any of your rights over your personal
                                        information. If you do provide us with personal information you understand that
                                        we will collect, hold, use and disclose it in accordance with this privacy
                                        policy. At any time you maintain the right to request access to the personal
                                        data which we may hold or process about you. You have the right to oblige us to
                                        correct any inaccuracies in your data free of charge. At any stage, you also
                                        have the right to ask us to stop using your personal data. If we receive
                                        personal information about you from a third party, we will protect it as set out
                                        in this privacy policy. If you are a third party providing personal information
                                        about somebody else, you represent and warrant that you have such person’s
                                        consent to provide the personal information to us. If you have previously agreed
                                        to us using your personal information for direct marketing purposes, you may
                                        change your mind at any time. We will provide you with the ability to
                                        unsubscribe from our email database or opt-out of communications. Please be
                                        aware we may need to request specific information from you to help us confirm
                                        your identity. If you believe that any information we hold about you is
                                        inaccurate, out of date, incomplete, irrelevant, or misleading, please contact
                                        us using the details provided in this privacy policy. We will take reasonable
                                        steps to correct any information found to be inaccurate, incomplete, misleading,
                                        or out of date. If you believe that we have breached a relevant data protection
                                        law and wish to make a complaint, please contact us using the details below and
                                        provide us with full details of the alleged breach. We will promptly investigate
                                        your complaint and respond to you, in writing, setting out the outcome of our
                                        investigation and the steps we will take to deal with your complaint. You also
                                        have the right to contact a regulatory body or data protection authority in
                                        relation to your complaint.</p>
                                    <h4>Limits of Our Policy</h4>
                                    <p>Our website may link to external sites that are not operated by us. Please be
                                        aware that we have no control over the content and policies of those sites, and
                                        cannot accept responsibility or liability for their respective privacy
                                        practices.</p>
                                    <h4>Changes to This Policy</h4>
                                    <p>At our discretion, we may change our privacy policy to reflect updates to our
                                        business processes, current acceptable practices, or legislative or regulatory
                                        changes. If we decide to change this privacy policy, we will post the changes
                                        here at the same link by which you are accessing this privacy policy and also
                                        update the modification date. If required by law, we will get your permission or
                                        give you the opportunity to opt in to or opt out of, as applicable, any new uses
                                        of your personal information.</p>

                                    <h4>Contact Us</h4>
                                    <p>By using our service, registering an account, or making a purchase, you consent
                                        to this Privacy Policy. For any questions or concerns regarding your privacy,
                                        you may contact us using the following details:
                                        ➢ https://vc.bcc.gov.bd
                                        ➢ bnda@bcc.gov.bd</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        </div>
    </div>
</div>
)
}