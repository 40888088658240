import axios from 'axios';
import React, { useState } from 'react'


export default function Terms() {


    return (
        <div>
            <div>
                <div className='container-fluid user-container border-top'>
                    <main id="main">

                        <section id="breadcrumbs" className="breadcrumbs">
                            <div className="container">
                                <ol>
                                    <li><a href="/app">Home</a></li>
                                    <li>Terms & Conditions</li>
                                </ol>
                            </div>
                        </section>

                        <section className="tg-may-account-wrapp tg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 detail">
                                        <div className="inner">
                                            <h2>Terms & Conditions</h2>
                                            <h4>Introduction</h4>
                                            <p>
                                                Welcome to Boithok subscription (https://vc.bcc.gov.bd/#/subscription). We are an online video conferencing platform and these are the terms and conditions governing your access and use of https://vc.bcc.gov.bd/ along with its related sub-domains, sites, future mobile app, services, and tools (the “Site”). By using the Site, you hereby accept these terms and conditions (including the linked information) and represent that you agree to comply with these terms and conditions of this User Agreement. This agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, use, or place an order on this Site. This Site is owned and operated by Bangladesh Government’s e-Government Computer Incident Response Team (BGD e-GOV CIRT), Bangladesh Computer Council (BCC). The Site reserves the right to change, modify, add, or remove portions of these Terms and Conditions at any time without any prior notification. Changes will be effective when posted on the Site with no other notice provided. Please check these Terms and Conditions regularly for updates. Your continued use of the Site following the posting of changes to Terms and Conditions of use constitutes your acceptance of those changes.
                                            </p>
                                            <h4>General Terms</h4>
                                            <p>
                                                By accessing and placing an order with Boithok, the User / Customer confirms that they are in agreement with and bound by the terms of service contained in the Terms and Conditions are outlined below. These terms apply to the entire website and any e-mail or other type of communication between the User / Customer and Boithok. Under no circumstances shall Boithok be liable for incidental damage including but not limited to loss of data or profit and the need for servicing or repair of equipment or data arising from the use or inability to use the materials on this site. Boithok reserves the right to change prices and revise the resources usage policy at any moment.
                                            </p>
                                            <h4>Platform for Communication</h4>
                                            <p>
                                                You agree, understand, and acknowledge that the Site is an online platform that enables you to purchase products listed at the price indicated therein at any time from any location using a payment method of your choice. You further agree and acknowledge that we are not in control of any transactions in any manner on a payment gateway as made available to you by an independent service provider. Accordingly, the contract of sale of products and/or services on the Site shall be a the strictly bipartite contract between you and us, while the payment processing occurs between you, us, and in case of payments with electronic cards your issuer bank. Accordingly, the contract of payment on the Site shall be strictly a bipartite contract between you and the payment service provider as listed on our Site.
                                            </p>
                                            <h4>License</h4>
                                            <p>
                                                Boithok grants you a revocable, non-exclusive, non-transferable, limited license to download and use our service strictly in accordance with the terms of this Agreement. These Terms and Conditions are a contract between you and Boithok (‘Boithok’, ‘we’, ‘us’ or ‘our’), the provider of the Boithok website. If you do not agree with the Terms and Conditions, please do not use the service. In case of violation of any of the Terms and Conditions, we reserve the right to cancel or block your access to our A site without notice.
                                            </p>
                                            <h4>Payment</h4>
                                            <p>
                                                When you subscribe/purchase our services, you agree to pay all fees or charges in accordance with the fees, charges, and billing terms in effect at the time each fee or charge is due and payable. As a condition for the transaction, unless otherwise indicated, you must provide us with a valid payment card (Visa, Mastercard, or any other issuer accepted by us). Your Payment Provider agreement governs your use of the designated payment card and you must refer to that agreement and not these Terms and Conditions to determine the rights and liabilities with your payment provider. By sharing with us your payment card number and associated information, you give us the authorization to verify information immediately, and subsequently invoice your account for all fees and charges due and payable to us and that no additional notice or consent is required. You agree to immediately notify us of any changes to your billing address or the card used for payment. We reserve the right to make changes to pricing and billing methods by immediately posting on our Site. No contract will exist between you and us until we accept your order by a confirmatory email, SMS, or other appropriate means of communication. You are responsible for any third-party fees that may incur (overseas credit card taxes, foreign currency rate, etc.) while placing an order for our service.
                                            </p>
                                            <h4>Links to other Websites</h4>
                                            <p>
                                                Our service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to their site. We strongly advise reviewing the Terms and Conditions of every site you visit. We have no control over and assume no responsibility for the content, Terms and Conditions, Privacy Policy, and practices of any third-party sites or services.
                                            </p>
                                            <h4>Modifications and Updates to Our Service</h4>
                                            <p>We reserve the right to modify, suspend or discontinue, temporarily or permanently, the service or any service t which it connects, with or without notice and liability. We may from time to time perform enhancement or improvements to the features / the functionality of the service, which may include patches, bug fixes, updates, upgrades other modifications. Updates may modify or delete certain features and / or functionalities of our service. You agree that we have no obligation to provide any updates at all or continue to provide or enable any particular feature and/or functionalities of the service to you. You further agree that all updates are deemed to constitute an integral part of service and are subject to the Terms and Conditions of this agreement.
                                            </p>
                                            <h4>Intellectual Property</h4>
                                            <p>Our platform and its entire content, feature, and functionality, including but not limited to all information, software, text, display, images, audio and video and the design, selection, and arrangement thereof are owned by us, the licensors, or other providers of such material and are protected by international copyright, trademark, patent, trade secret, and other intellectual property and proprietary rights laws. The material may not be copied, modified, reproduced, or distributed in any way, in whole or in part, without express prior permission, unless mentioned in these Terms and Conditions. Any unauthorized use of the materials is prohibited.</p>
                                            <h4>Promotions</h4>
                                            <p>We may, from time to time, include promotions, contests, sweepstakes, or other activities that require you to submit information or material concerning yourself. Please note that all Promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. We strongly recommend reviewing all promotions rules to determine your eligibility and decision to participate. You are responsible to abide by and complying with all promotions rules, should you choose to enter any promotion.</p>
                                            <h4>Privacy</h4>
                                            <p>Please review our Privacy Agreement, which also governs your visit to the Site. The personal information/data provided to us by you or your use of the Site will be treated as strictly confidential, in accordance with the Privacy Agreement and applicable laws and regulations. If you object to your information being transferred or used in the manner specified in the Privacy Agreement, please do not use the Site.</p>
                                            <h4>Your Suggestion</h4>
                                            <p>Any feedback, suggestions, comments, or improvement ideas you might make with regards to our service shall be considered non-confidential and non-proprietary and remain our sole and exclusive property. We are free to use, copy, modify, publish or redistribute the suggestions for any purpose without in any way credit or compensation to you.</p>
                                            <h4>Typographical Errors</h4>
                                            <p>In the event, a product and/or service is listed at an incorrect price or with incorrect information due to typographical errors, we shall reserve the right to refuse or cancel any order placed for the product and/or service listed at the incorrect price or information.</p>
                                            <h4>Changes to our Terms and Conditions</h4>
                                            <p>You acknowledge and agree that we may stop providing the service or any feature within the service, temporarily or permanently at our discretion and without prior notice. If we decide to change our Terms and Conditions, we will update this page to reflect those changes and post the modification date below.</p>
                                            <h4>Entirety of Agreement</h4>
                                            <p>This Terms and Conditions agreement, together with the Privacy Policy and any other legal notices published by us on the Site constitute the entire agreement between you and us concerning our services. If any provision of this agreement is deemed invalid or outdated by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of other provisions of the agreement, which shall remain in full force and effect.</p>
                                            <h4>Term and Termination</h4>
                                            <p>This agreement shall remain in effect until terminated by you or us. We may in our sole discretion, at any time, for any or no reason suspend or terminate this agreement with or without prior notice. In the event you fail to comply with any provision of this agreement, it will be terminated immediately. Termination of this agreement will not limit any of our rights and remedies at law in case of breach of any of the obligations by you during the term of the agreement.</p>
                                            <h4>Your Consent</h4>
                                            <p>We have structured our Terms and Conditions to provide complete transparency into what is being set when you visit our site. By using our service and making a purchase, you hereby consent to our Terms and Conditions.</p>
                                            <h4>Contact Us</h4>
                                            <p>Email: bnda@bcc.gov.bd Link: https://vc.bcc.gov.bd/#/subscription
                                                Address: Bangladesh Computer Council, E-14/X, ICT Tower, Agargaon, Dhaka-1207, Bangladesh.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </main>
                </div>
            </div>
        </div>
    )
}
